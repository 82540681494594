import React from "react";
import { Navbar, Container, Nav, Dropdown, Image } from "react-bootstrap";
import { useTheme } from "../context/ThemeContext";
import { useNavigate } from "react-router-dom";
import care from "../pages/care.png";
import tworks from "../pages/tworks.png";


const Header = () => {
  const { theme, toggleTheme } = useTheme();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (

    <Navbar
      bg={theme === "dark" ? "dark" : "light"}
      variant={theme === "dark" ? "dark" : "light"}
    >
      <Container>  

        <Nav className="me-auto d-flex align-items-center">
          <Navbar.Brand href="/" className="d-flex align-items-center">
            <Image
              src={tworks}
              alt="T-WORKS ICON"
              width="100"
              height="70"
              className="me-2"
            />
            <Image
              src={care}
              alt="CARE ICON"
              width="100"
              height="70"
              className="me-2"
            />

            <span style={{ fontFamily: "Roboto, sans-serif" }}>
              ICS Dashboard
            </span>

          </Navbar.Brand>
        </Nav>

        <Nav className="ms-auto d-flex align-items-center">
          <button
            onClick={toggleTheme}
            className="btn btn-outline-secondary me-3"
          >
            {theme === "dark" ? "Light Mode" : "Dark Mode"}
          </button>

          <Dropdown>

            <Dropdown.Toggle variant="outline-secondary" id="admin-dropdown">
              <i className="bi bi-person-circle" /> Admin
            </Dropdown.Toggle>

            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>

          </Dropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
