import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import {
  Autocomplete,
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import Header from "../components/Header";
import "./map.css";
import cook from "./box.png";



const mapContainerStyle = {
  width: "100%",
  height: "750px",
};


const points = [
  { lat: 17.437462, lng: 78.468288 },
  { lat: 17.468, lng: 78.5788 },
  { lat: 17.628, lng: 78.448288 },
];


const center = {
  lat: 17.385044,
  lng: 78.486671,
};


const darkModeStyle = [
  { elementType: "geometry", stylers: [{ color: "#212121" }] },
  { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#757575" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#212121" }] },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#bdbdbd" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [{ color: "#2c2c2c" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#8a8a8a" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#000000" }],
  },
];

const HomePage = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDawx-PwOvSV24Rkk4WPvvwe79gDDmWIyI",
    libraries: ["places"],
  });

  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [currentTime, setCurrentTime] = useState("");
  const [latitude, setLatitude] = useState("17.4345");
  const [longitude, setLongitude] = useState("17.8534");
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const formattedTime = now.toLocaleString();
      setCurrentTime(formattedTime);
    };

    updateTime();

    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onAutocompleteLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const location = place.geometry?.location;
      if (location) {
        const newPosition = {
          lat: location.lat(),
          lng: location.lng(),
        };
        setSelectedPosition(newPosition);
        map.panTo(newPosition);
        map.setZoom(14);
      }
    }
  };

  return (
    <>
      <Header />
      <Container fluid>
        <Row className="mt-4">
          <Col lg={6} className="mb-4">
            {isLoaded ? (
              <div style={{ position: "relative", height: "100%" }}>
                <Autocomplete
                  onLoad={onAutocompleteLoad}
                  onPlaceChanged={onPlaceChanged}
                  fields={["geometry"]}
                >
                  <input
                    type="text"
                    placeholder="Search place"
                    style={{
                      boxSizing: "border-box",
                      border: "1px solid transparent",
                      width: "240px",
                      padding: "0 12px",
                      height: "40px",
                      borderRadius: "3px",
                      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                      fontSize: "16px",
                      position: "absolute",
                      top: "10px",
                      zIndex: "10",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                    ref={autocompleteRef}
                  />
                </Autocomplete>

                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={center}
                  zoom={10}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    styles: darkModeStyle,
                  }}
                >
                  {points.map((point, i) => (
                    <MarkerF key={i} position={point} />
                  ))}
                  {selectedPosition && <MarkerF position={selectedPosition} />}
                </GoogleMap>
              </div>
            ) : (
              <></>
            )}
          </Col>

          <Col lg={6}>
            <center>
              <div className="image-container mb-3">
                <img src={cook} alt="placeholder" className="img-fluid" />
              </div>
            </center>

            <div className="d-flex justify-content-between mb-3">
              <Card
                className="flex-fill me-2"
                style={{ width: "50%", height: "100px" }}
              >
                <Card.Body style={{ marginTop: "10px" }}>
                  🕒 Current Time and Date: <b>{currentTime}</b>
                </Card.Body>
              </Card>
              <Card className="flex-fill ms-2" style={{ width: "50%" }}>
                <Card.Body style={{ marginTop: "10px" }}>
                  📌 Latitude: <b>{latitude}</b>, Longitude: <b>{longitude}</b>
                </Card.Body>
              </Card>
            </div>

            <div
              className="d-flex justify-content-between mb-3"
              style={{ marginTop: "10px" }}
            >
              <Card
                className="flex-fill me-2"
                style={{ width: "50%", height: "100px" }}
              >
                <Card.Body>
                  Address :{" "}
                  <b>
                    Survey No. 83/1, Plot 1/D, 1/E, 1/F, Silpa Gram Craft
                    Village, Rai Durg, Hyderabad, Telangana 500081
                  </b>
                </Card.Body>
              </Card>
              <Card className="flex-fill ms-2" style={{ width: "50%" }}>
                <Card.Body style={{ marginTop: "10px" }}>
                  Forced Draft :<b>8 Hr</b>
                </Card.Body>
              </Card>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <Card
                className="flex-fill me-2"
                style={{ width: "50%", height: "100px" }}
              >
                <Card.Body>
                  Run Time Natural Draft : <b>1 Hr</b>
                </Card.Body>
              </Card>
              <Card className="flex-fill ms-2" style={{ width: "50%" }}>
                <Card.Body>
                  🔋 Battery Temperature : <b>39°C</b>
                </Card.Body>
              </Card>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <Card
                className="flex-fill me-2"
                style={{ width: "50%", height: "100px" }}
              >
                <Card.Body style={{ marginTop: "10px" }}>
                  🌱 Carbon Emission: 33Kg/C
                </Card.Body>
              </Card>
              <Card className="flex-fill ms-2" style={{ width: "50%" }}>
                <Card.Body>
                  ⌛ Total Runtime : <b> 4 HOURS</b>{" "}
                  <div>Since 01st Oct 2024</div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};



export default HomePage;
