import React from "react";
import Header from "../components/Header";

const Dashboard = () => {
  return (
    <>
      <Header />
      <div className="container mt-4">
        <h1>Dashboard</h1>
        <p>Welcome to the ICS Dashboard!</p>
      </div>
    </>
  );
};

export default Dashboard;
